import { Grid, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import MinimizeIcon from '@mui/icons-material/Minimize';
import image01 from '../images/gallery01.jpg';
import image02 from '../images/gallery02.jpg';

function Gallery() {
    const colorYellow = "#F5B041";
    const images = [
        {
            src: image01,
            alt: "gallery01",
        },
        {
            src: image02,
            alt: "gallery02",
        },
        {
            src: image01,
            alt: "gallery01",
        },
        {
            src: image02,
            alt: "gallery02",
        },
        {
            src: image01,
            alt: "gallery01",
        },
        {
            src: image02,
            alt: "gallery02",
        },
        {
            src: image01,
            alt: "gallery01",
        },
        {
            src: image02,
            alt: "gallery02",
        },
    ];
    return (
        <Box sx={{mt:15, pl: { md: 3, xs: "auto" } }}>
            <Grid container spacing={2}>                
                <Grid item md={6} sx={{textAlign:'left', pr:5}}>
                    <Typography sx={{ fontSize: 32, fontWeight: 'bold' }}>
                        Nuestra Galeria
                    </Typography>
                    <Typography sx={{color:colorYellow }}>
                        <MinimizeIcon sx={{mr:1}}/> Diseño de calidad al precio más justo
                    </Typography>
                </Grid>
                <Grid item md={6}>
                    <Typography sx={{my:1, textAlign: "justify"}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum fuga aperiam saepe nulla, similique aspernatur perspiciatis optio veniam inventore illo delectus consequatur assumenda voluptate. There are manny variations.</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{my: 5, textAlign: { xs: "center" }}}> 
                {
                    images.map(function (item,index) {
                        return (
                            <Grid item md={3} xs={12} sx={{ p: 0 }} key={index}>
                                <img src={item.src} alt={item.alt} width="100%" height="100%" />
                            </Grid>
                        );
                    })
                }              
            </Grid>
        </Box>
    );
}

export default Gallery;