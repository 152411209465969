import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const colorBlue = "#0277BD";

const BlueButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(colorBlue),
    backgroundColor: colorBlue,
    '&:hover': {
        backgroundColor: colorBlue,
    },
}));

export default BlueButton;