import { Grid, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import MinimizeIcon from '@mui/icons-material/Minimize';
import BlueButton from "../tools/BlueButton";
import image01 from '../images/gallery01.jpg';
import image02 from '../images/gallery02.jpg';
import { Fragment } from "react";
import Service from './Service';
import Project from './Project';

function About() {
    const colorYellow = "#F5B041";
    
    return (
        <Fragment>
            <Box sx={{ mt: 20, pl: { md: 3, xs: "auto" } }}>
                <Grid container spacing={2}>
                    <Grid item md={6} sx={{ textAlign: 'left', pr: 5 }}>
                        <Typography sx={{ fontSize: 32, fontWeight: 'bold' }}>
                            Desde que iniciamos a trabajar en 2020
                        </Typography>
                        <Typography sx={{ color: colorYellow }}>
                            <MinimizeIcon sx={{ mr: 1 }} /> Diseño de calidad al precio más justo
                        </Typography>
                    </Grid>
                    <Grid item md={6}>
                        <Typography sx={{ my: 1, textAlign: "justify" }}>
                            Hemos trabajado en algunas de las asombrosas maravillas arquitectónicas dentro de industrias como hoteles, edificios residenciales, oficinas, edificios comerciales, alimentos y bebidas y los hemos convertido en grandes éxitos.
                        </Typography>

                        <Typography sx={{ my: 2, textAlign: "justify" }}>
                            Hemos trabajado en algunas de las asombrosas maravillas arquitectónicas dentro de industrias como hoteles, edificios residenciales, oficinas, edificios comerciales, alimentos y bebidas y los hemos convertido en grandes éxitos.
                        </Typography>

                        <Typography sx={{ color: colorYellow, textAlign: "right" }}>Lorem ipsum</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 8 }}>
                    <Grid item md={6} sx={{ p: 8, display: { md: 'block', xs: 'none' } }}>
                        <img src={image01} alt="image01" width="80%" height="80%" />
                    </Grid>
                    <Grid item md={6}>
                        <Typography sx={{ fontSize: 32, fontWeight: 'bold', textAlign: "left" }}>
                            Estéticamente Agradable.
                        </Typography>
                        <Typography sx={{ my: 2, textAlign: "justify" }}>
                            Hemos trabajado en algunas de las asombrosas maravillas arquitectónicas dentro de industrias como hoteles, edificios residenciales, oficinas, edificios comerciales, alimentos y bebidas y los hemos convertido en grandes éxitos.
                        </Typography>
                        <Box sx={{ textAlign: "left" }}>
                            <BlueButton variant="contained">Leer más</BlueButton>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: {xs: 2 } }}>
                    <Grid item md={6} sx={{ p: { md: 3, xs: "auto" } }}>
                        <Typography sx={{ fontSize: 32, fontWeight: 'bold', textAlign: "left" }}>
                            Gran ética de trabajo.
                        </Typography>
                        <Typography sx={{ my: 2, textAlign: "justify" }}>
                            Hemos trabajado en algunas de las asombrosas maravillas arquitectónicas dentro de industrias como hoteles, edificios residenciales, oficinas, edificios comerciales, alimentos y bebidas y los hemos convertido en grandes éxitos.
                        </Typography>
                        <Box sx={{ textAlign: "left" }}>
                            <BlueButton variant="contained">Leer más</BlueButton>
                        </Box>
                    </Grid>
                    <Grid item md={6} sx={{ p: 8, display: { md: 'block', xs: 'none' } }}>
                        <img src={image02} alt="image02" width="80%" height="80%" />
                    </Grid>
                </Grid>
            </Box>
            <Service></Service>
            <Project></Project>
        </Fragment>
    );
}

export default About;