import * as React from 'react';
import { Grid } from "@mui/material";
import Box from '@mui/material/Box';
import picture from '../images/building.jpg';
import Portal from '@mui/material/Portal';
import Button from '@mui/material/Button';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import WhiteButton from '../tools/WhiteButton';

function Main() {
    const colorYellow = "#F5B041";
    const [show, setShow] = React.useState(false);
    const container = React.useRef(null);

    const handleClick = () => {
        setShow(!show);
    };
    return (
        <Box sx={{mt:3, pl:3 }}>
            <Grid container spacing={2}>
                <Grid item md={6} sx={{textAlign:'left',  }}>
                    <Box sx={{ fontSize: 44, fontWeight: 'bold' }}>
                        Los reyes de la <span style={{color:colorYellow}}> construcción</span> edificaron grandes cosas.
                    </Box>
                    <Box sx={{mt:3}}>
                        <Box sx={{ my: 1 }}>
                            Antark es una empresa profesional de construcción, renovación y rehabilitación. Somos parte del Grupo innovador con sede en Perú.
                            {show ? (
                                <Portal container={container.current}>
                                    <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum fuga aperiam saepe nulla, similique aspernatur perspiciatis optio veniam inventore illo delectus consequatur assumenda voluptate. Laudantium culpa repellat expedita amet accusantium?</span>
                                </Portal>
                            ) : null}
                        </Box>
                        <Box sx={{ pr: 5, my: 1 }} ref={container} />
                        <Button onClick={handleClick} sx={{ textTransform: "inherit", color:colorYellow }}>
                            {show ? 'Ver menos' : 'Leer más'}
                        </Button>                        
                    </Box>
                </Grid>
                <Grid item  md={6}>
                    <Box sx={{display:"inline-block", position:"relative"}}>
                        <img src={picture} alt="home" width="100%" height="100%" />
                        <WhiteButton href="/">Nuestros Proyectos <ArrowRightAltIcon sx={{ml:1}}/></WhiteButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Main;