import { AppBar, Button, Drawer, IconButton, List, ListItem, ListItemText, Toolbar } from "@mui/material";
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import BlueButton from "../tools/BlueButton";
import logo from '../images/logo.png';
import { useState } from "react";

function Header() {
    const [state, setState] = useState({
        top: false,
    });
    const anchor ="left";  
    const options = [
        { 
            name: "INICIO",
            url: "/",
        },
        { 
            name: "SOBRE",
            url: "/",
        },
        { 
            name: "CONTÁCTANOS",
            url: "/",
        },
        { 
            name: "UBICACIÓN",
            url: "/",
        },
    ]; 
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
    };
    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {options.map((item, index) => (
                    <ListItem button key={item.name}>
                        <ListItemText primary={item.name} />
                    </ListItem>
                ))}
            </List>            
        </Box>
    );
    
    return (
        <Box sx={{ flexGrow: 1, mt:3 }} >
            <AppBar position="static" color="transparent" elevation={0}>
                <Toolbar>
                    <IconButton 
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2, display: { md: 'none' } }}   
                        onClick={toggleDrawer(anchor, true)}                     
                    >
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                    <Box sx={{ flexGrow: 1}} textAlign="left">
                        <img src={logo} alt="Logo" width="148" height="39" />
                    </Box>
                    
                     <Box sx={{display: { md: 'block', xs: 'none'} }}   >                 
                        {options.map((item, index) => (
                            <Button key={item.name} color="inherit" sx={{ mr: 2 }}>{item.name}</Button>
                        ))}                     
                    </Box>
                    <BlueButton variant="contained" onClick={() => {
                        window.location.href = process.env.REACT_APP_CLIENT_URL;
                    }}>Ver Ofertas</BlueButton>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default Header;