import { Grid, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import write from '../images/write.png';

function Service() {
    
    return (
        <Box sx={{pl: { md: 3, xs: "auto" }, mt: {xs: 8 } }}>
            <Typography sx={{ fontSize: 32, fontWeight: 'bold', textAlign: { xs: "left", md: "center" } }}>
                Proporcionamos los siguientes servicios:
            </Typography>
            <Grid container spacing={2} sx={{my:3, mx: { xs: "auto" }}} >                               
                <Grid item md={4} xs={12} sx={{textAlign:'center', pr:5}}>
                    <img src={write} alt="service01" width="50" height="50" /> 
                    <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>
                        Venta minorista y carpinteria
                    </Typography>
                    <Typography sx={{my:1, textAlign: { xs: "center", md: "justify" } }}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </Typography>
                </Grid>
                <Grid item md={4} xs={12} sx={{textAlign:'center', pr:5}}>
                    <img src={write} alt="service01" width="50" height="50" /> 
                    <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>
                        Construcción
                    </Typography>
                    <Typography sx={{my:1, textAlign: { xs: "center", md: "justify" }}}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </Typography>
                </Grid>
                <Grid item md={4} xs={12} sx={{textAlign:'center', pr:5}}>
                    <img src={write} alt="service01" width="50" height="50" /> 
                    <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>
                        Planificación de interiores y espacios
                    </Typography>
                    <Typography sx={{my:1, textAlign: { xs: "center", md: "justify" }}}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Service;