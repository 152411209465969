import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const colorWhite = "#FFFFFF";

const WhiteButton = styled(Button)(({ theme }) => ({
    backgroundColor: colorWhite,
    color: "#0277BD",
    fontWeight: "bold",
    border:"1px solid #ffffff",
    padding: 15,
    position: "absolute", 
    bottom:8, 
    right: 3,
    '&:hover': {
        backgroundColor: colorWhite,       
        border:"1px solid #0277BD",
    },
}));

export default WhiteButton;