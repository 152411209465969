import { Grid, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import image01 from '../images/gallery01.jpg';
import image02 from '../images/gallery02.jpg';

function Project() {
    const colorWhite ="#FFFFFF"
    return (
        <Box sx={{pl: { md: 3, xs: "auto" }, mt: {xs: 8 } }}>
            <Typography sx={{ fontSize: 32, fontWeight: 'bold', textAlign: { xs: "left", md: "center" } }}>
                Nuestros Últimos Proyectos:
            </Typography>
            <Grid container spacing={2} sx={{my:3, mx: { xs: "auto" }}} >                               
                <Grid item md={4} xs={12}>
                    <Box sx={{display:"inline-block", position:"relative"}}>
                        <img src={image01} alt="project01" width="100%" height="100%" />
                        <Box sx={{
                                backgroundColor: colorWhite,
                                position: "absolute", 
                                bottom:8, 
                                left:3, 
                                padding:1, 
                                textAlign: "left"
                            }}>
                            <Typography>Laywes Hub</Typography>
                            <Typography>Kenya</Typography>
                        </Box>                        
                    </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Box sx={{display:"inline-block", position:"relative"}}>
                        <img src={image02} alt="project02" width="100%" height="100%" />
                        <Box sx={{
                                backgroundColor: colorWhite,
                                position: "absolute", 
                                bottom:8, 
                                left:3, 
                                padding:1, 
                                textAlign: "left"
                            }}>
                            <Typography>E.A.C</Typography>
                            <Typography>Arusha</Typography>
                        </Box>                        
                    </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Box sx={{display:"inline-block", position:"relative"}}>
                        <img src={image01} alt="project03" width="100%" height="100%" />
                        <Box sx={{
                                backgroundColor: colorWhite,
                                position: "absolute", 
                                bottom:8, 
                                left:3, 
                                padding:1, 
                                textAlign: "left"
                            }}>
                            <Typography>Transnational</Typography>
                            <Typography>Bank</Typography>
                        </Box>                        
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Project;