import {Grid, Typography } from "@mui/material";
import { Box} from "@mui/system";
import MinimizeIcon from '@mui/icons-material/Minimize';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import Button from '@mui/material/Button';

function Footer() {
    const colorYellow = "#F5B041";
    return (
        <Box sx={{background:"black", color: "white", p: { md: 5, xs: 2 }}}>
            <Grid container spacing={2} sx={{mt: 5}}>                
                <Grid item md={4} xs={12}>
                    <Typography sx={{fontSize: 20 }}>
                        Sobre la empresa
                    </Typography>
                    <Typography sx={{textAlign: { md: "left", xs: "center" } }}>
                        <MinimizeIcon sx={{mr:1, color: colorYellow}}/> Antark es una empresa profesional de construcción, renovación y rehabilitación. Somos parte del Grupo innovador con sede en Perú.
                    </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Typography sx={{ fontSize: 20, mt:{xs: 5, md: 0}} }>
                        Ubicación
                    </Typography>
                    <Typography sx={{textAlign: "center" }}>
                       Calle A, Urb B.
                       La Libertad, Perú
                    </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Typography sx={{ fontSize: 20, mt:{xs: 5, md: 0} }}>
                        Siguenos
                    </Typography>
                    
                    <Grid container>
                        <Grid item md={12} xs={12} sx={{mt:1, textAlign:"center", mx: {xs: 3, md: 0} }}>
                            <Button variant="text" href="/" sx={{color:"white", textTransform:"inherit"}}> 
                                <FacebookIcon sx={{mr:3}}/>wayraant
                            </Button>
                        </Grid>
                        <Grid item md={12} xs={12} sx={{mt:1, textAlign:"center", mx: {xs: 3, md: 0} }}>
                            <Button variant="text" href="/" sx={{color:"white", textTransform:"inherit"}}> 
                                <TwitterIcon sx={{mr:3}}/>wayraant
                            </Button>
                        </Grid>
                        <Grid item md={12} xs={12} sx={{mt:1, textAlign:"center", mx: {xs: 3, md: 0} }}>
                            <Button variant="text" href="/" sx={{color:"white", textTransform:"inherit"}}> 
                                <InstagramIcon sx={{mr:3}}/>wayraant
                            </Button>
                        </Grid>
                        <Grid item md={12} xs={12} sx={{mt:1, textAlign:"center", mx: {xs: 3, md: 0} }}>
                            <Button variant="text" href="/" sx={{color:"white", textTransform:"inherit"}}> 
                                <PhotoCameraFrontIcon sx={{mr:3}}/>wayraant
                            </Button>
                        </Grid>                     
                    </Grid>  
                </Grid>                
            </Grid>
            <Box sx={{mt: {xs:5, md: 15}}}>
                <Typography>
                    © ANTARK Todos los derechos reservados 2021
                </Typography>
                <Button variant="text" href="/" sx={{color:"white", textTransform:"inherit"}}>Inicio</Button>
                <Button variant="text" href="/" sx={{color:"white", textTransform:"inherit"}}>Sobre</Button>
                <Button variant="text" href="/" sx={{color:"white", textTransform:"inherit"}}>Contacto</Button>
                <Button variant="text" href="/" sx={{color:"white", textTransform:"inherit"}}>Servicios</Button>

            </Box>
        </Box>
    );
}

export default Footer;