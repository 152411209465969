import { Container } from '@mui/material';
import './App.css';
import About from './components/About';
import Footer from './components/Footer';
import Gallery from './components/Gallery';
import Header from './components/Header';
import Main from './components/Main';

function App() {
  return (
    <Container>
      <div className="App">
        <Header></Header>
        <Main></Main>
        <About></About>
        <Gallery></Gallery>
        <Footer></Footer>
      </div>
    </Container>
  );
}

export default App;
